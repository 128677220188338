import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Modal,
  ModalBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import TimePicker from "react-time-picker";
import "flatpickr/dist/themes/dark.css";
import TagUsersComponent from "../uiComponents/TagUsersComponent";
import {
  DollarSign,
  Calendar,
  Clock,
  Percent,
  Image,
  Tag,
} from "react-feather";
import { useMutation, useQueryClient } from "react-query";
import { createEvent } from "../../services/eventsService";
import * as Yup from "yup";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import { getUploadURL } from "../../utils/getUploadURL";
import { uploadFileToS3Promise } from "../../utils/uploadFileToS3Promise";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const formSchema = Yup.object()
  .shape({
    title: Yup.string()
      .required("Title is required")
      .min(3, "Title must be at least 3 letters")
      .max(25, "Title cannot be more than 25 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description must be at least 10 letters")
      .max(250, "Description cannot be more than 250 characters"),
    thumbnail: Yup.mixed()
      .required("Thumbnail is Required")
      .test(
        "FILE_FORMAT",
        "Only the following formats are accepted: .jpeg, .jpg, .png, .gif, .webp and .heic",
        (value) => {
          if (!value) return false;
          return [
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/webp",
            "image/heic",
            "image/jpg",
          ].includes(value.type);
        }
      ),
    price: Yup.number()
      .required("Price is required")
      .min(3, "Price should be at least $3"),
    discountedPrice: Yup.number()
      .required("Pre-sale price is required")
      .min(3, "Price should be at least $3")
      .test(
        "is-less-than-price",
        "Pre-sale price should be less than the price.",
        function (value) {
          const { price } = this.parent;
          return value < price;
        }
      ),
    commission: Yup.number()
      .required("Commission is required")
      .min(0, "Commission cannot be less than 0")
      .max(100, "Commission cannot be more than 100")
      .typeError("Commission must be a percentage"),
    bonus: Yup.number()
      .required("Highest ticket seller bonus is required")
      .min(0, "Highest ticket seller bonus cannot be less than 0")
      .max(100, "Highest ticket seller bonus cannot be more than 100")
      .typeError("Highest ticket seller bonus must be a percentage"),
    startDate: Yup.date()
      .required("Start date is required")
      .typeError("Start date must be a valid date")
      .min(moment().startOf("day"), "Start date cannot be in the past"),
    endDate: Yup.date()
      .required("End date is required")
      .typeError("End date must be a valid date")
      .min(
        Yup.ref("startDate"),
        "End date cannot be earlier than the start date"
      ),
    startTime: Yup.string()
      .required("Start time is required")
      .typeError("Start time is required")
      .matches(
        /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
        "Start time must be in HH:mm format"
      ),
    endTime: Yup.string()
      .required("End time is required")
      .typeError("End time is required")
      .matches(
        /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
        "End time must be in HH:mm format"
      ),
  })
  .test({
    name: "startTime",
    test: function (values) {
      const { startDate, startTime } = values;

      if (startDate && startTime) {
        const momentStartDateTime = moment(startDate);
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        momentStartDateTime.set({
          hour: startHours,
          minute: startMinutes,
          second: 0,
          millisecond: 0,
        });

        const now = moment();

        if (momentStartDateTime.isBefore(now)) {
          return this.createError({
            path: "startTime",
            message: "The start time cannot be in the past.",
          });
        }
      }
      return true;
    },
  })
  .test({
    name: "endTime",
    test: function (values) {
      const { startDate, endDate, startTime, endTime } = values;

      if (startDate && endDate && startTime && endTime) {
        const momentStartDate = moment(startDate);
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        momentStartDate.set({
          hour: startHours,
          minute: startMinutes,
          second: 0,
          millisecond: 0,
        });

        const momentEndDate = moment(endDate);
        const [endHours, endMinutes] = endTime.split(":").map(Number);
        momentEndDate.set({
          hour: endHours,
          minute: endMinutes,
          second: 0,
          millisecond: 0,
        });

        if (momentStartDate.isSameOrAfter(momentEndDate)) {
          return this.createError({
            path: "endTime",
            message: "Please ensure the end time is later than the start time.",
          });
        }
      }
      return true;
    },
  });

function NewEventModal({ isModalOpen, closeModal }) {
  const [taggedUsers, setTaggedUsers] = useState(null);
  const queryClient = useQueryClient();
  const [timePickerKey, setTimePickerKey] = useState({
    timer1: Math.random(),
    timer2: Math.random(),
  });

  const mutation = useMutation(
    (newEvent) => {
      return createEvent(newEvent);
    },
    {
      onSuccess: (data) => {
        toast.success(data.data.message);
        queryClient.invalidateQueries("events");
      },
    }
  );

  const handleChangeStatus = async (files, status, setFieldValue) => {
    try {
      if (status === "removed") {
        setFieldValue("thumbnail", null);
        return;
      }

      // Early validation checks
      if (!files?.file) {
        toast.error("No file selected");
        return;
      }

      // Check file format
      const fileType = files.file.type;

      // Only process if status is 'done'
      if (status !== "done") return;

      // Handle HEIC files
      if (fileType === "image/heic" || fileType === "") {
        const heic2any = await import("heic2any");

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async () => {
            try {
              const response = await fetch(reader.result);
              const blob = await response.blob();
              const conversionResult = await heic2any.default({ blob });

              setFieldValue("thumbnail", conversionResult);

              resolve();
            } catch (error) {
              console.error("Error processing HEIC file:", error);
              toast.error("Failed to process HEIC file");
              reject(error);
            }
          };

          reader.onerror = (error) => {
            console.error("FileReader error:", error);
            toast.error("Error reading file");
            reject(error);
          };

          reader.readAsDataURL(files.file);
        });
      }

      // Handle other image files
      return new Promise((resolve) => {
        setFieldValue("thumbnail", files.file);
        resolve();
      });
    } catch (error) {
      console.error("Unexpected error in file handling:", error);
      toast.error("An unexpected error occurred while processing the file");
    }
  };

  const initialValues = {
    title: "",
    description: "",
    price: "",
    discountedPrice: "",
    commission: "",
    bonus: "",
    startDate: null,
    endDate: null,
    startTime: "",
    endTime: "",
    thumbnail: undefined,
    taggedUsers: null,
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      //Find difference between start datetime and end datetime in minutes
      const { startDate, endDate, startTime, endTime } = values;

      const momentStartDate = moment(startDate);
      const [startHours, startMinutes] = startTime.split(":").map(Number);
      momentStartDate.set({
        hour: startHours,
        minute: startMinutes,
        second: 0,
        millisecond: 0,
      });

      const momentEndDate = moment(endDate);
      const [endHours, endMinutes] = endTime.split(":").map(Number);
      momentEndDate.set({
        hour: endHours,
        minute: endMinutes,
        second: 0,
        millisecond: 0,
      });

      const durationInMinutes = momentEndDate.diff(momentStartDate, "minutes");

      //Upload image to s3
      const renamedThumbnail = new File([values.thumbnail], uuidv4(), {
        type: values.thumbnail.type,
        lastModified: values.thumbnail.lastModified,
      });
      const uploadURLResponse = await getUploadURL();
      if (!uploadURLResponse) {
        throw new Error("Error uploading thumbnail");
      }
      await uploadFileToS3Promise(
        renamedThumbnail,
        uploadURLResponse.url,
        uploadURLResponse.headers
      );

      // Perform necessary data transformation
      const eventData = {
        title: values.title,
        description: values.description,
        image: uploadURLResponse.location,
        original_price: values.price,
        discounted_price: values.discountedPrice,
        comission: values.commission,
        bonus: values.bonus,
        start_date_time: momentStartDate.toISOString(),
        duration: durationInMinutes,
        tag_performer: taggedUsers.map((taggedUser) => taggedUser.id).join(","),
      };

      // Call mutation to create event
      await mutation.mutateAsync(eventData);

      // Reset form and close modal on success
      resetForm();
      closeModal();
    } catch (error) {
      toast.error(
        error?.response?.data?.message ??
          "Something went wrong while creating an event, please try again later."
      );
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      centered={true}
      toggle={closeModal}
      size="sm"
      className="newEventModal"
    >
      <div className="modal-close-btn" onClick={closeModal}>
        <span aria-hidden="true">×</span>
      </div>
      <ModalBody className="p-0 border-none">
        <h1>New Event</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="newEventFormOuter">
                {/* Title Field */}
                <FormGroup>
                  <InputGroup>
                    <Field
                      as={Input}
                      style={{ paddingLeft: "10px" }}
                      type="text"
                      name="title"
                      placeholder="Title"
                      disabled={isSubmitting}
                    />
                  </InputGroup>
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                {/* Description Field */}
                <FormGroup>
                  <Field
                    as={Input}
                    type="textarea"
                    name="description"
                    rows="3"
                    placeholder="Description"
                    disabled={isSubmitting}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                {/* Thumbnail Dropzone */}
                <FormGroup>
                  <Field name="thumbnail">
                    {({ field, form }) => (
                      <div className="newEventUploadGroup">
                        <Dropzone
                          {...field}
                          onChangeStatus={(files, status) => {
                            handleChangeStatus(
                              files,
                              status,
                              form.setFieldValue
                            );
                          }}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                          accept="image/*"
                          styles={{
                            dropzone: {
                              minHeight: 84,
                              maxHeight: 84,
                              border: "2px dashed #CEA43D",
                              borderRadius: "4px",
                            },
                            dropzoneActive: { borderColor: "green" },
                          }}
                          disabledisabled={isSubmitting}
                        />
                        <div className="uploadText">
                          <Image />
                          <p>Upload Image</p>
                        </div>
                      </div>
                    )}
                  </Field>
                  <ErrorMessage
                    name="thumbnail"
                    component="div"
                    className="text-danger"
                  />
                </FormGroup>

                <div className="formRow">
                  {/* Price Field */}
                  <div className="formCol">
                    <FormGroup className="position-relative has-icon-left">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <DollarSign />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          as={Input}
                          name="price"
                          min="0"
                          placeholder="Original Price"
                          disabled={isSubmitting}
                        />
                      </InputGroup>
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </div>

                  {/* Pre-sale Price Field */}
                  <div className="formCol">
                    <FormGroup className="position-relative has-icon-left">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <DollarSign />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          as={Input}
                          name="discountedPrice"
                          min="0"
                          placeholder="Pre-sale Price"
                          disabled={isSubmitting}
                        />
                      </InputGroup>
                      <ErrorMessage
                        name="discountedPrice"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </div>

                  {/* Commission Field */}
                  <div className="formCol">
                    <FormGroup className="position-relative has-icon-left">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Percent />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          as={Input}
                          name="commission"
                          min="0"
                          placeholder="Commission"
                          disabled={isSubmitting}
                        />
                      </InputGroup>
                      <ErrorMessage
                        name="commission"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </div>

                  {/* Bonus Field */}
                  <div className="formCol">
                    <FormGroup className="position-relative has-icon-left">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Percent />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Field
                          as={Input}
                          name="bonus"
                          min="0"
                          placeholder="Highest ticket seller"
                          disabled={isSubmitting}
                          style={{ paddingRight: "1rem" }}
                        />
                      </InputGroup>
                      <ErrorMessage
                        name="bonus"
                        component="div"
                        className="text-danger"
                      />
                    </FormGroup>
                  </div>

                  {/* Start Date Field */}
                  <div className="formCol">
                    <div className="dateInputField">
                      <div className="icon">
                        <Calendar />
                      </div>
                      <Field name="startDate">
                        {({ field, form }) => (
                          <Flatpickr
                            {...field}
                            className="form-control"
                            placeholder="Start Date"
                            onChange={(date) =>
                              form.setFieldValue("startDate", date[0])
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                              defaultDate: new Date(),
                            }}
                            // TODO: add disabled state
                            disabled={isSubmitting}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="startDate"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  {/* End Date Field */}
                  <div className="formCol">
                    <div className="dateInputField">
                      <div className="icon">
                        <Calendar />
                      </div>
                      <Field name="endDate">
                        {({ field, form }) => (
                          <Flatpickr
                            {...field}
                            className="form-control"
                            placeholder="End Date"
                            onChange={(date) =>
                              form.setFieldValue("endDate", date[0])
                            }
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d",
                              defaultDate: new Date(),
                            }}
                            // TODO: add disabled state
                            disabled={isSubmitting}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="endDate"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  {/* Start Time Field */}
                  <div className="formCol">
                    <div className="timeInputField">
                      <div className="icon">
                        <Clock />
                      </div>
                      <Field name="startTime">
                        {({ field, form }) => (
                          <TimePicker
                            {...field}
                            key={timePickerKey.timer1}
                            style={{ color: "#fff" }}
                            format="h:mm a"
                            onChange={(time) => {
                              if (!time) {
                                setTimePickerKey((prevState) => ({
                                  timer1: Math.random(),
                                  timer2: prevState.timer2,
                                }));
                              }
                              form.setFieldValue("startTime", time);
                            }}
                            placeholder="Start Time"
                            disabled={isSubmitting}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="startTime"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  {/* End Time Field */}
                  <div className="formCol">
                    <div className="timeInputField">
                      <div className="icon">
                        <Clock />
                      </div>
                      <Field name="endTime">
                        {({ field, form }) => (
                          <TimePicker
                            {...field}
                            key={timePickerKey.timer2}
                            style={{ color: "#fff" }}
                            format="h:mm a"
                            onChange={(time) => {
                              if (!time) {
                                setTimePickerKey((prevState) => ({
                                  timer1: prevState.timer1,
                                  timer2: Math.random(),
                                }));
                              }
                              form.setFieldValue("endTime", time);
                            }}
                            placeholder="End Time"
                            disabled={isSubmitting}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="endTime"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  </div>

                  <ErrorMessage
                    name="start-end-datetime"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  {/* Tag Users Field */}
                  <div className="formCol taggedInputCol">
                    <div className="icon">
                      <Tag />
                    </div>

                    <TagUsersComponent
                      setTaggedUsers={setTaggedUsers}
                      placeholder="Tag other performers"
                      disableInput={isSubmitting}
                    />
                  </div>
                </div>
                {/* Submit Button */}
                <Button.Ripple
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div className="relative">
                      Creating Event
                      <Spinner
                        style={{
                          color: "#fff",
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "10px",
                          margin: "auto",
                        }}
                        size="md"
                      />
                    </div>
                  ) : (
                    "Create Event"
                  )}
                </Button.Ripple>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
}

export default NewEventModal;
