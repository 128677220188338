import React, { useState } from "react";
import { Calendar, Edit2, PlusCircle } from "react-feather";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NewPostModal from "../components/modals/NewPostModal";
import { useHistory } from "react-router-dom";
import NewEventModal from "./modals/NewEventModal";
import { connect } from "react-redux";

const CreateDropdown = ({ user }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(null);
  const history = useHistory();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="createArticlesDropdownBtn"
      >
        <DropdownToggle>
          <PlusCircle />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => setModalOpen("post")}>
            <Edit2 /> Create a Post
          </DropdownItem>
          {user?.is_go_live === 1 ? (
            <DropdownItem onClick={() => setModalOpen("event")}>
              <Calendar /> Create an Event
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </Dropdown>

      <NewPostModal
        isModalOpen={modalOpen === "post"}
        closeModal={() => setModalOpen(null)}
        history={history}
      />
      <NewEventModal
        isModalOpen={modalOpen === "event"}
        closeModal={() => setModalOpen(null)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.app.user,
  };
};

export default connect(mapStateToProps)(CreateDropdown);
