import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { Play, AtSign, X } from "react-feather";
import axios from "axios";
import { toast } from "react-toastify";
import SuggestionsDropdown from "../SuggestionsDropdown";
import { userFuzzySearch } from "../../services/postService";
import { IKImage } from "imagekitio-react";
import Avatar from "../../views/pages/AvatarComponent";
import verificationBadge from "../../assets/img/icons/verification-badge.png";
import { Link } from "react-router-dom";

let signal;

const TagUsersComponent = ({
  setTaggedUsers,
  disableInput,
  header,
  footer,
  showSelectionInPill = true,
  placeholder = "Enter a name to tag co-performer",
  taggedUsers = null,
  addUserAction = null,
  removeSelectedUsersAction = null,
  showSearchBar = true,
}) => {
  const [usersInPost, setUsersInPost] = useState([]);
  const [query, setQuery] = useState("");
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (query) {
      setIsLoadingSuggestions(true);
      timeout = setTimeout(() => getListOfSuggestedUsers(), 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [query]); // eslint-disable-line

  useEffect(() => {
    if (!!taggedUsers) {
      setUsersInPost(taggedUsers);
    }
  }, []); // eslint-disable-line

  const getListOfSuggestedUsers = async () => {
    setIsLoadingSuggestions(true);
    const SIGNAL_CANCEL_MESSAGE = "signal_cancelled";
    if (signal) {
      signal.cancel(SIGNAL_CANCEL_MESSAGE);
    }
    signal = axios.CancelToken.source();

    try {
      const response = await userFuzzySearch(
        { search: query, isModel: true },
        {
          cancelToken: signal.token,
        }
      );
      signal = undefined;
      if (response.data.data) {
        setUserSuggestions(response.data.data);
      } else {
        setUserSuggestions([]);
      }
      setIsLoadingSuggestions(false);
    } catch (err) {
      if (!!err.message && err.message !== SIGNAL_CANCEL_MESSAGE) {
        setUserSuggestions([]);
        setIsLoadingSuggestions(false);
      }
    }
  };

  useEffect(() => {
    setTaggedUsers(usersInPost);
  }, [usersInPost]); // eslint-disable-line

  const removeSelectedUser = (id) => {
    const filteredUsers = usersInPost.filter((user) => user.id !== id);
    setUsersInPost(filteredUsers);

    if (removeSelectedUsersAction) {
      removeSelectedUsersAction(id);
    }
  };

  const clickSuggestedUserHandler = (user) => {
    const ifSelectedUserAlready = usersInPost.some(
      (splitUser) => splitUser.id === user.id
    );

    if (ifSelectedUserAlready) {
      showToast("You have already selected this user");
      setQuery("");
      setUserSuggestions([]);
      return;
    }

    if (addUserAction) {
      addUserAction(user.id);
    }

    setQuery("");
    setUserSuggestions([]);
    setUsersInPost([
      ...usersInPost,
      {
        id: user.id,
        nickName: user.nickName,
        username: user.username,
        image: user.image,
        is_creator: user.is_creator,
        is_user_verified: user.is_user_verified,
      },
    ]);
  };

  const showToast = (message) => {
    toast.warn(message, {
      toastId: message,
    });
  };

  return (
    <div className="split-earnings">
      {header ? (
        <p>
          <Play />
          {header}
        </p>
      ) : null}
      <div className="tagging-box-outer">
        <div className="tagging-input-field-outer">
          <div className="holder">
            <div className="tagging-input-field">
              <div className="form-label-group">
                {header ? (
                  <div className="tagging-icon">
                    <AtSign />
                  </div>
                ) : null}

                {showSearchBar ? (
                  <Input
                    type="text"
                    name="text"
                    value={query}
                    placeholder={placeholder}
                    onChange={(e) => setQuery(e.target.value)}
                    autoComplete="off"
                    disabled={disableInput}
                  />
                ) : null}
                {query && (
                  <SuggestionsDropdown
                    query={query}
                    users={userSuggestions}
                    isLoading={isLoadingSuggestions}
                    clickSuggestedUserHandler={clickSuggestedUserHandler}
                    setUserSuggestions={setUserSuggestions}
                  />
                )}
              </div>
            </div>
          </div>
          {usersInPost.length > 0 ? (
            <SelectedUsers
              showSelectionInPill={showSelectionInPill}
              removeSelectedUser={removeSelectedUser}
              disableInput={disableInput}
              usersInPost={usersInPost}
            />
          ) : null}
          {footer ? (
            <small>
              *You and the co-performers will have to sign a release form
            </small>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const SelectedUsers = ({
  showSelectionInPill,
  removeSelectedUser,
  disableInput,
  usersInPost,
}) => {
  return (
    <div>
      {showSelectionInPill ? (
        // pill style design
        <div className="tagging-list">
          {usersInPost.map((user) => (
            <p key={user.id}>
              {user.nickName ?? user.username}
              {!disableInput && (
                <span
                  className="cancel-tagging"
                  onClick={() => removeSelectedUser(user.id)}
                >
                  <X />
                </span>
              )}
            </p>
          ))}
        </div>
      ) : (
        //Live stream tagging design
        <div className="live-stream-tagging-list-data">
          <ul>
            {usersInPost.map((user) => (
              <li key={user.id}>
                <Link to={`/${user.username}`} target="_blank">
                  <div className="contentt d-flex">
                    {user.image ? (
                      <IKImage
                        path={user.image}
                        transformation={[
                          {
                            width: 60,
                            height: 60,
                          },
                        ]}
                        height="60"
                        width="60"
                      />
                    ) : (
                      <Avatar
                        content={
                          user.nickName?.charAt(0) || user.username?.charAt(0)
                        }
                        className="marg-0 avatar"
                        size="sm"
                      />
                    )}

                    <div className="user-name-info">
                      <p>
                        {user.nickName ?? user.username}&nbsp;
                        {(!!user.is_creator ||
                          (!user.is_creator && !!user.is_user_verified)) && (
                          <span>
                            <img
                              src={verificationBadge}
                              alt="verification badge"
                            />
                          </span>
                        )}
                      </p>
                      <span>@{user.username}</span>
                    </div>
                  </div>
                </Link>
                {!disableInput && (
                  <span
                    className="cancel-tagging"
                    onClick={() => removeSelectedUser(user.id)}
                  >
                    <X />
                  </span>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TagUsersComponent;
