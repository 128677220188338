import ServiceClient from "./serviceClientConfig";

const serviceClient = new ServiceClient({
  baseURL: process.env.REACT_APP_APIS,
});

export const login = (payload) => {
  return serviceClient.post("v1/auth/login", payload);
};

export const register = (payload) => {
  return serviceClient.post("v1/auth/register", payload);
};

export const logout = () => {
  return serviceClient.post("v1/auth/logout");
};

export const logoutOtherDevices = (payload) => {
  return serviceClient.post("v1/auth/logout-others", payload);
};

export const forgotPassword = (payload) => {
  return serviceClient.post("v1/auth/forget-password", payload);
};

export const resetPassword = (payload) => {
  return serviceClient.post("v1/auth/reset-password", payload);
};

export const getUser = () => {
  return serviceClient.get("v1/auth/me");
};

export const loginAsModerator = (payload) => {
  return serviceClient.post("v1/auth/token-login", payload);
};

export const requestModelAccount = (payload) => {
  return serviceClient.post("v1/users/request-to-model", payload);
};

export const addPhoneNumber = (payload) => {
  return serviceClient.post("v1/auth/phone_update", payload);
};

export const updateTimezone = () => {
  return serviceClient.post("v1/auth/update-timezone", {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};
